import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29512cd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"font-size":"1.2rem"} }
const _hoisted_3 = {
  key: 0,
  style: {"font-size":"0.8rem","color":"#e5e5e5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { button: $props.isButton }, {
    default: _withCtx(() => [
      ($props.title || $props.subtitle || $props.icon || $props.value)
        ? (_openBlock(), _createBlock(_component_ion_card_header, {
            key: 0,
            class: "ion-no-padding",
            color: "primary",
            style: {"--ion-color-primary":"rgba(23, 81, 120, 0.6)"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                detail: $props.showHeaderDetailButton,
                button: $props.showHeaderDetailButton,
                style: {"--background":"transparent"}
              }, {
                default: _withCtx(() => [
                  ($props.icon && ($props.icon.includes('image/svg') || $props.icon.includes('.svg')))
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        style: {"margin-inline-end":"16px"},
                        slot: "start",
                        size: "large",
                        src: $props.icon
                      }, null, 8, ["src"]))
                    : ($props.icon)
                      ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                          key: 1,
                          style: {"--size":"32px"},
                          slot: "start"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", { src: $props.icon }, null, 8, _hoisted_1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", _hoisted_2, [
                        _createElementVNode("b", null, _toDisplayString($props.title), 1)
                      ]),
                      ($props.subtitle)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.subtitle), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  ($props.value != null)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 2,
                        color: $props.badgeColor,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($props.value), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true),
                  ($props.actionBtnText)
                    ? (_openBlock(), _createBlock(_component_ion_buttons, {
                        key: 3,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            fill: "solid",
                            color: "success",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClickActionBtn()))
                          }, {
                            default: _withCtx(() => [
                              ($props.actionBtnIcon)
                                ? (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 0,
                                    icon: $props.actionBtnIcon
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true),
                              _createTextVNode(" " + _toDisplayString($props.actionBtnText), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["detail", "button"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!$setup.isSlotEmpty)
        ? (_openBlock(), _createBlock(_component_ion_card_content, {
            key: 1,
            class: _normalizeClass({ 'small-pane': $props.paneSize == 'small', 'large-pane': $props.paneSize == 'large', 'no-padding': $props.noPadding })
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["button"]))
}